module.exports = [{
      plugin: require('/Users/Alex/Documents/Coding/newGatsbyPort/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#6b63ff","showSpinner":false},
    },{
      plugin: require('/Users/Alex/Documents/Coding/newGatsbyPort/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"###","head":true},
    },{
      plugin: require('/Users/Alex/Documents/Coding/newGatsbyPort/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Alex/Documents/Coding/newGatsbyPort/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
